import React, { FC, SyntheticEvent, useState } from 'react';

import { Input, LoadingOverlay, PrimaryButton } from 'components';
import { ChevronRightIcon } from 'shared/icons';
import styles from './ClaimForm.module.scss';
import { claimDeal } from 'shared/api/v2/deals/deals.utils';
import {
    createCustomer,
    getCustomerStatus,
    joinCustomer,
} from 'shared/api/v2/customers/customers.utils';
import { ErrorMessages } from 'shared/constants/errorMessages';
import { DealKind, TSourceKey } from 'shared/types';
import {
    CustomerStatus,
    TCustomerStatus,
} from 'shared/api/v2/customers/customers.types';

export interface IClaimFormProps {
    customerEmail: string;
    setCustomerEmail: (email: string) => void;
    merchantId: string;
    dealKind: DealKind;
    dealId: string;
    campaignId?: string;
    source: TSourceKey;
    isRootMerchant: boolean;
    setIsThankYouOpen: (isOpen: boolean) => void;
    setIsCustomerModalOpen: (isOpen: boolean) => void;
    setCustomerId: (customerId: string) => void;
    setCustomerStatus: (status: TCustomerStatus) => void;
}

const ClaimForm: FC<IClaimFormProps> = ({
    customerEmail,
    dealId,
    dealKind,
    isRootMerchant,
    merchantId,
    setCustomerEmail,
    setCustomerId,
    setCustomerStatus,
    setIsCustomerModalOpen,
    setIsThankYouOpen,
    source,
    campaignId,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    if (!merchantId) {
        return null;
    }

    const onEmailChange = (value: string): void => {
        setCustomerEmail(value);
    };
    const onClear = (): void => {
        setCustomerEmail('');
    };

    const onSubmit = async (evt: SyntheticEvent<HTMLFormElement>) => {
        evt.preventDefault();
        setIsLoading(true);
        setError('');
        try {
            const { data: customerInfo, error } = await getCustomerStatus({
                email: customerEmail,
                merchantId,
            });
            if (error || !customerInfo) {
                setError(error?.email || ErrorMessages.DEFAULT);
                return;
            }
            setCustomerId(customerInfo.customer_id);
            setCustomerStatus(customerInfo.status);
            if (isRootMerchant) {
                if (
                    customerInfo?.status === CustomerStatus.NOT_FOUND ||
                    customerInfo?.status === CustomerStatus.NOT_CONNECTED
                ) {
                    setIsCustomerModalOpen(true);
                    return;
                }
                if (customerInfo?.status === CustomerStatus.CONNECTED) {
                    const { error } = await claimDeal(
                        dealKind,
                        dealId as string,
                        {
                            customer_id: customerInfo.customer_id,
                            source,
                        }
                    );
                    if (error) {
                        setError(error);
                        return;
                    }
                    setIsThankYouOpen(true);
                }
            } else {
                if (
                    customerInfo?.status === CustomerStatus.CONNECTED &&
                    dealKind === 'ahd'
                ) {
                    setError('This deal is only available to new customers.');
                    return;
                }
                if (customerInfo?.status === CustomerStatus.NOT_FOUND) {
                    const { data: newCustomer, error } = await createCustomer({
                        email: customerEmail,
                        merchant_id: merchantId,
                        source,
                        campaign_id: campaignId,
                    });
                    if (error || !newCustomer) {
                        setError(error?.email || ErrorMessages.DEFAULT);
                        return;
                    }
                    if (newCustomer?.customer_id) {
                        setCustomerId(newCustomer.customer_id);
                        setCustomerStatus('connected');
                        const { error } = await claimDeal(
                            dealKind,
                            dealId as string,
                            {
                                customer_id: newCustomer.customer_id,
                                source,
                            }
                        );
                        if (error) {
                            setError(error);
                            return;
                        }
                        setIsCustomerModalOpen(true);
                    }
                    return;
                }
                if (customerInfo?.status === CustomerStatus.NOT_CONNECTED) {
                    const { error } = await joinCustomer(
                        customerInfo?.customer_id,
                        {
                            merchant_id: merchantId,
                            source,
                            campaign_id: campaignId,
                        }
                    );
                    if (error) {
                        setError(error || ErrorMessages.DEFAULT);
                        return;
                    }
                }
                const { error } = await claimDeal(dealKind, dealId as string, {
                    customer_id: customerInfo.customer_id,
                    source,
                });
                if (error) {
                    setError(error);
                    return;
                }
                setIsThankYouOpen(true);
            }
        } catch (error: unknown) {
            console.error(error);
            setError(ErrorMessages.DEFAULT);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={styles.Form}>
            <form onSubmit={onSubmit} className={styles.Form}>
                <Input
                    hasIcon
                    value={customerEmail}
                    onChange={onEmailChange}
                    onClear={onClear}
                    type="email"
                    label="Email"
                    placeholder="ex: john.smith@email.com"
                    className={styles.Input}
                    errorMessage={error}
                />
                <PrimaryButton
                    disabled={!customerEmail || isLoading}
                    type="submit"
                    icon={<ChevronRightIcon />}
                    className={styles.SubmitButton}
                >
                    Claim deal
                </PrimaryButton>
                {isLoading && <LoadingOverlay />}
            </form>
        </div>
    );
};

export default ClaimForm;
