import { format, sub } from 'date-fns';
import { FormatPastDate } from './formatPastDate.types';

/**
 * Format ISO dates in the form accepted by the backend.
 * The `options` param may be used to print past date relative to `date` param.
 * In case of an invalid `date` string value, the function returns an empty string.
 */
export const formatPastDate: FormatPastDate = (
    /** it can be a timestamp, a Date object or Date() constructor argument */
    date = new Date(),
    /** format that is a bit different from Moment.js
     * @see https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md */
    dateFormat = 'y-MM-dd',
    /** object describing time to subtract from `date` param */
    options = {}
) => {
    try {
        date = typeof date === 'string' ? new Date(date) : date;
        const formattedDate = format(
            sub(date || new Date(), options),
            dateFormat
        );
        return formattedDate;
    } catch (e) {
        console.error(e);
        return '';
    }
};
