import { StylesConfig } from 'react-select';
import {
    COLOR_BLACK,
    COLOR_BLUE,
    COLOR_RED,
} from '../../shared/constants/colors';

export const customStyles = (isError?: boolean): StylesConfig => ({
    menuList: (baseStyles) => ({
        ...baseStyles,
        maxHeight: '160px',
    }),
    control: (baseStyles, state) => ({
        ...baseStyles,
        fontSize: '16px',
        padding: '3px 12px',
        boxSizing: 'border-box',
        ...(isError
            ? {
                  border: `1px solid ${COLOR_RED[70]}`,
                  '&:hover': {
                      border: `1px solid ${COLOR_RED[70]}`,
                  },
              }
            : {
                  border: state.isFocused
                      ? `1px solid ${COLOR_BLUE[50]}`
                      : `1px solid ${COLOR_BLACK}`,
                  '&:hover': {
                      border: state.isFocused
                          ? `1px solid ${COLOR_BLUE[50]}`
                          : `1px solid ${COLOR_BLACK}`,
                  },
              }),
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    menu: (baseStyles) => ({
        ...baseStyles,
        padding: '4px 6px',
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        borderRadius: '4px',
        fontSize: '14px',
        color: state.isSelected ? COLOR_BLUE[50] : 'initial',
        background: state.isSelected ? COLOR_BLUE[10] : 'initial',
        '&:hover': {
            background: COLOR_BLUE[10],
        },
    }),
    dropdownIndicator: (baseStyles, state) => ({
        ...baseStyles,
        transition: 'all .2s ease',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'initial',
    }),
});
