import { FC, useRef } from 'react';
import styles from './DateInput.module.scss';

type Props = {
    value: string;
    /** function called when value is updated from user input*/
    onChange: (date: string) => void;
    /** start date */
    min?: string;
    /** end date */
    max?: string;
    /** label placed on top of input */
    label?: string;
    placeholder?: string;
    /** error label. If not empty, input will have styles for error state */
    errorMessage?: string;
    /** should have focus */
    hasAutofocus?: boolean;
    /** should be required */
    required?: boolean;
};

const DateInput: FC<Props> = ({
    value,
    onChange,
    label,
    min,
    max,
    placeholder,
    errorMessage,
    hasAutofocus = false,
    required,
}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <div className={styles.Wrapper}>
            {label && (
                <label
                    htmlFor="date-input"
                    className={styles.Label}
                    id="date-desc"
                >
                    {label}
                </label>
            )}
            <div className={styles.InputWrapper}>
                <input
                    id="date-input"
                    value={value}
                    onChange={(evt) => onChange(evt.currentTarget.value)}
                    type="date"
                    min={min}
                    max={max}
                    className={styles.DateInput}
                    required={required}
                    placeholder={placeholder}
                    ref={inputRef}
                    autoFocus={hasAutofocus}
                />
            </div>
            {Boolean(errorMessage) && (
                <span
                    className={styles.ErrorMessage}
                    data-testid="email-error-message"
                >
                    {errorMessage}
                </span>
            )}
        </div>
    );
};

export default DateInput;
