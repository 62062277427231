import {
    ClaimDeal,
    ClaimDealErrorResponse,
    TClaimDealUtilReturn,
} from './deals.types';

import {
    claimUniqueCodeDeal,
    claimCustomerServiceDeal,
    claimAdhocDeal,
} from './deals.data';
import { DealKind } from 'shared/types';
import { ErrorMessages } from 'shared/constants/errorMessages';

const mapErrorCodeToMessage = (error: string) => {
    switch (error) {
        case 'validation_error':
            return ErrorMessages.INVALID_INPUT;
        case 'deal_expired':
            return ErrorMessages.ALREADY_EXPIRED;
        case 'deal_not_started':
            return ErrorMessages.NOT_STARTED;
        case 'deal_already_claimed':
            return ErrorMessages.ALREADY_CLAIMED;
        case 'internal_server_error':
        case 'merchant_group_without_sub_locations':
        case 'merchant_not_found':
        case 'deal_not_found':
            return ErrorMessages.DEFAULT;
        default:
            return ErrorMessages.DEFAULT;
    }
};

export const claimDeal = async (
    dealKind: DealKind,
    ...args: Parameters<ClaimDeal>
): Promise<TClaimDealUtilReturn> => {
    const claimDealMethods = {
        ucd: claimUniqueCodeDeal,
        csd: claimCustomerServiceDeal,
        ahd: claimAdhocDeal,
    };
    const claimDealMethod = claimDealMethods[dealKind];
    if (!claimDealMethod) {
        throw new Error(`Unknown deal kind: ${dealKind}`);
    }
    try {
        const response = await claimDealMethod(...args);
        if (response.ok) {
            return { error: null };
        }
        const error: ClaimDealErrorResponse = await response.json();
        return { error: mapErrorCodeToMessage(error.error) };
    } catch {
        return { error: ErrorMessages.NETWORK_ISSUE };
    }
};
