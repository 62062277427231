import {
    ClaimDeal,
    ClaimDealPayload,
    GetUniqueCodeDealInfo,
    GetCustomerServiceDealInfo,
    GetAdhocDealInfo,
} from './deals.types';
import { getFetchConfig } from 'shared/utils';
import { V2_URL } from '../common';

/**
 ** Unique Code Deals
 **/

export const claimUniqueCodeDeal: ClaimDeal = async (dealId, payload) => {
    const config = getFetchConfig<ClaimDealPayload>({
        method: 'POST',
        payload,
    });
    return fetch(`${V2_URL}/deals/unique-code/${dealId}/claim/`, config);
};

export const getUniqueCodeDealInfo: GetUniqueCodeDealInfo = async (
    dealId,
    source
) => {
    return fetch(`${V2_URL}/deals/unique-code/${dealId}?source=${source}`);
};

/**
 ** Customer Service Deals
 **/

export const claimCustomerServiceDeal: ClaimDeal = async (dealId, payload) => {
    const config = getFetchConfig<ClaimDealPayload>({
        method: 'POST',
        payload,
    });
    return fetch(`${V2_URL}/deals/customer-service/${dealId}/claim/`, config);
};

export const getCustomerServiceDealInfo: GetCustomerServiceDealInfo = async (
    dealId,
    source
) => {
    return fetch(`${V2_URL}/deals/customer-service/${dealId}?source=${source}`);
};

/**
 ** Adhoc Deals
 **/

export const claimAdhocDeal: ClaimDeal = async (dealId, payload) => {
    const config = getFetchConfig<ClaimDealPayload>({
        method: 'POST',
        payload,
    });
    return fetch(`${V2_URL}/deals/ad-hoc/${dealId}/claim/`, config);
};

export const getAdhocDealInfo: GetAdhocDealInfo = async (dealId, source) => {
    return fetch(`${V2_URL}/deals/ad-hoc/${dealId}?source=${source}`);
};
