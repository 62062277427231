import {
    ErrorResponse,
    InternalErrorResponse,
    TMerchantAddress,
    TSourceKey,
} from 'shared/types';

/**
 ** Common types
 **/

export type GetDealInfo = (
    dealId: string,
    source?: TSourceKey
) => Promise<Response>;

/* Claim */

export type ClaimDealPayload = {
    customer_id: string;
    source?: TSourceKey;
};
/* Claim */

export type ClaimDeal = (
    dealId: string,
    payload: ClaimDealPayload
) => Promise<Response>;

export type ClaimErrorCodes =
    | 'customer_not_found'
    | 'deal_expired'
    | 'merchant_group_without_sub_locations'
    | 'deal_already_claimed'
    | 'deal_not_found'
    | 'merchant_not_found';

export type ClaimDealErrorResponse =
    | ErrorResponse<ClaimErrorCodes, 'api_exception'>
    | InternalErrorResponse;

export type TClaimDealUtilReturn = Promise<{
    error: string | null;
}>;

/**
 ** Unique Code Deals
 **/

/* Deal Info */
type TMenuItem = {
    id: string;
    name: string;
};

export type TCashDiscountConfig = {
    value: number | null;
    min_order_value?: number | null;
};

export type TPercentDiscountConfig = {
    percent: number | null;
    min_order_value?: number | null;
    max_discount_value?: number | null;
};

export type TFreeItemDiscountConfig = {
    min_order_value?: number | null;
    item: TMenuItem;
};

export type TBogoDiscountConfig = {
    item: TMenuItem;
};

export type TDiscountConfig =
    | TCashDiscountConfig
    | TPercentDiscountConfig
    | TFreeItemDiscountConfig
    | TBogoDiscountConfig;

export type DiscountType =
    | 'percent'
    | 'fixed'
    | 'free_item'
    | 'buy_one_get_one';

export type GetUniqueCodeDealInfoResponse = {
    deal_name: string;
    merchant_id: string;
    discount_type: DiscountType;
    discount_config: TDiscountConfig;
    start_datetime: string;
    end_datetime: string;
    timezone: string;
    locations: TMerchantAddress[];
    thumbnail: string | null;
};

export type GetUniqueCodeDealInfoErrorResponse =
    | ErrorResponse<
          | 'deal_not_found'
          | 'merchant_not_found'
          | 'deal_expired'
          | 'deal_not_started',
          'api_exception'
      >
    | InternalErrorResponse;

export type GetUniqueCodeDealInfo = GetDealInfo;

/**
 ** Customer Service Deals
 **/

/* Deal Info */

export type GetCustomerServiceDealInfoResponse = Omit<
    GetUniqueCodeDealInfoResponse,
    'discount_type' | 'discount_config'
>;

export type GetCustomerServiceDealInfoErrorResponse =
    GetUniqueCodeDealInfoErrorResponse;

export type GetCustomerServiceDealInfo = GetDealInfo;

/**
 ** Adhoc Deals
 **/

/* Deal Info */

export type GetAdhocDealInfoResponse = Omit<
    GetUniqueCodeDealInfoResponse,
    'start_datetime' | 'end_datetime' | 'locations'
>;

export type GetAdhocDealInfoErrorResponse = GetUniqueCodeDealInfoErrorResponse;

export type GetAdhocDealInfo = GetDealInfo;

export const isAdhocDealInfoResponse = (
    response: GetAdhocDealInfoResponse | GetUniqueCodeDealInfoResponse
): response is GetAdhocDealInfoResponse => {
    return !(
        'start_datetime' in response ||
        'end_datetime' in response ||
        'locations' in response
    );
};

export const isUniqueCodeDealInfoResponse = (
    response: GetAdhocDealInfoResponse | GetUniqueCodeDealInfoResponse
): response is GetUniqueCodeDealInfoResponse => {
    return !isAdhocDealInfoResponse(response);
};
