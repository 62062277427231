import { ErrorMessages } from 'shared/constants/errorMessages';
import { GetValidationErrors } from './getValidationErrors.types';

const defaultMessage = ErrorMessages.GENERIC_VALIDATION_MESSAGE;

/**
 * Get error messages for form fields,
 * based on backend response
 */
export const getValidationErrors: GetValidationErrors = (response, mapping) => {
    if (!response?.message) {
        return null;
    }

    return Object.entries(response.message).reduce((acc, [key, value]) => {
        const message = mapping?.[key] || defaultMessage;
        return {
            ...acc,
            [key]:
                Array.isArray(value) && value?.[0]?.length
                    ? value?.[0]
                    : message,
        };
    }, {});
};
