import React, { FC } from 'react';

import { CustomerModalForm, Modal } from 'components';
import styles from './CustomerModal.module.scss';
import { ICustomerModalFormProps } from 'components/CustomerModalForm/CustomerModalForm';

type TModalFormProps = Omit<
    ICustomerModalFormProps,
    'onCancel' | 'setIsCustomerModalOpen'
>;

interface ICustomerModalProps extends TModalFormProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    isRootMerchant: boolean;
}

const CustomerModal: FC<ICustomerModalProps> = ({
    isOpen,
    setIsOpen,
    isRootMerchant,
    ...rest
}) => {
    const expiredDealMessage =
        "If you'd like, let us know a little more about you so that we can personalize your future offers.";
    const rootMerchantMessage =
        'Please share a bit more about yourself so that we can personalize your future offers';
    const defaultDealMessage = `Your deal code has been emailed to you. If you'd like, let us know a little more about you so that we can personalize your future offers.`;

    const description = rest.isExpiredDeal
        ? expiredDealMessage
        : isRootMerchant
        ? rootMerchantMessage
        : defaultDealMessage;

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            title={isRootMerchant ? 'Just one more step!' : 'Reward sent'}
            className={styles.Modal}
        >
            {!rest.isExpiredDeal && (
                <p className={styles.Title}>
                    {isRootMerchant ? 'Just one more step!' : 'Deal sent!'}
                </p>
            )}
            <p className={styles.Description}>{description}</p>
            <CustomerModalForm
                className={styles.Form}
                onCancel={() => {
                    rest.setIsDealSentOpen?.(true);
                    setIsOpen(false);
                }}
                isRootMerchant={isRootMerchant}
                setIsCustomerModalOpen={setIsOpen}
                {...rest}
            />
        </Modal>
    );
};

export default CustomerModal;
