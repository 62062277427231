import React, { FC } from 'react';

import { Modal } from 'components';
import styles from './DealSentModal.module.scss';

interface IDealSentModalProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

const DealSentModal: FC<IDealSentModalProps> = ({ isOpen, setIsOpen }) => (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} title="Reward sent">
        <div className={styles.ImageContainer}>
            <img
                src="/images/thankyou.png"
                alt=""
                aria-hidden="true"
                className={styles.Image}
            />
        </div>
        <p className={styles.Title}>Deal sent!</p>
        <p className={styles.Description}>
            Please check your inbox for your deal code and instructions on how
            to redeem.
        </p>
    </Modal>
);

export default DealSentModal;
