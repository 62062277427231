import styles from './DealDateCounter.module.scss';
import { ClockIcon } from '../../shared/icons';
import { formatDistanceStrict, isAfter, isEqual } from 'date-fns';
import React from 'react';

type DealDateCounterProps = {
    dealStartDate: Date;
    dealEndDate: Date;
};
export const DealDateCounter: React.FC<DealDateCounterProps> = ({
    dealStartDate,
    dealEndDate,
}) => {
    const today = new Date();
    const isDealStarted =
        isEqual(
            dealStartDate.setHours(0, 0, 0, 0),
            today.setHours(0, 0, 0, 0)
        ) || isAfter(today, dealStartDate);

    const dateDistance = (dateToCompare: Date) =>
        formatDistanceStrict(dateToCompare, today, {
            unit: 'day',
        });
    return (
        <div className={styles.DealDateCounter}>
            <ClockIcon />
            <span>
                {isDealStarted
                    ? `${dateDistance(dealEndDate)} left`
                    : `${dateDistance(dealStartDate)} until deal begins!`}
            </span>
        </div>
    );
};
