import {
    TUpdateCustomerUtilReturn,
    UpdateCustomer,
    UpdateCustomerErrorResponse,
    UpdateCustomerPayload,
    TJoinCustomerUtilReturn,
    JoinCustomer,
    JoinCustomerErrorResponse,
    TCreateCustomerUtilReturn,
    CreateCustomer,
    CreateCustomerErrorResponse,
    CreateCustomerResponse,
    TGetCustomerStatusUtilReturn,
    GetCustomerStatus,
    GetCustomerStatusResponse,
    GetCustomerStatusErrorResponse,
} from './customers.types';
import {
    updateCustomer as updateCustomerMethod,
    joinCustomer as joinCustomerMethod,
    createCustomer as createCustomerMethod,
    getCustomerStatus as getCustomerStatusMethod,
} from './customers.data';
import { getValidationErrors } from 'shared/utils/getValidationErrors/getValidationErrors';
import { ErrorMessages } from 'shared/constants/errorMessages';

const errorMessageMap: Record<keyof UpdateCustomerPayload, ErrorMessages> = {
    phone_number: ErrorMessages.FIELD_PHONE_NUMBER,
    birthday: ErrorMessages.FIELD_BIRTHDAY,
    full_name: ErrorMessages.FIELD_FULL_NAME,
};

const isCreateErrorResponse = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    obj: any
): obj is CreateCustomerErrorResponse => {
    return obj && typeof obj === 'object' && 'customer_info' in obj.message;
};

const mapErrorCodeToMessage = (
    errorResponse:
        | UpdateCustomerErrorResponse
        | CreateCustomerErrorResponse
        | GetCustomerStatusErrorResponse
) => {
    if (errorResponse.error === 'phone_not_available') {
        return {
            phone_number: ErrorMessages.PHONE_NUMBER_TAKEN,
        };
    }
    if (errorResponse.error === 'payload_parse_exception') {
        /*
        Note: The 'createCustomer' function handles errors differently, introducing an 'error.message' 
        structure with a 'customer_info' key containing the actual errors. While it would be more ideal 
        to have a flat, consistent structure, the current implementation deviates from this, 
        and this behavior is not yet documented in the Swagger.
        */
        const preparedError = isCreateErrorResponse(errorResponse)
            ? {
                  ...errorResponse,
                  message: {
                      ...errorResponse.message.customer_info,
                  },
              }
            : errorResponse;

        return getValidationErrors(preparedError, errorMessageMap);
    }
    if (errorResponse.error === 'customer_to_young') {
        return {
            birthday: ErrorMessages.FIELD_BIRTHDAY,
        };
    }
    return {
        generic: ErrorMessages.DEFAULT,
    };
};

export const updateCustomer = async (
    ...args: Parameters<UpdateCustomer>
): Promise<TUpdateCustomerUtilReturn> => {
    try {
        const response = await updateCustomerMethod(...args);
        if (response.ok) {
            return { error: null };
        }
        const error: UpdateCustomerErrorResponse = await response.json();
        return {
            error: mapErrorCodeToMessage(error),
        };
    } catch {
        return {
            error: {
                full_name: '',
                birthday: '',
                phone_number: '',
                generic: ErrorMessages.NETWORK_ISSUE,
            },
        };
    }
};

export const joinCustomer = async (
    ...args: Parameters<JoinCustomer>
): Promise<TJoinCustomerUtilReturn> => {
    try {
        const res = await joinCustomerMethod(...args);
        if (res.ok) {
            return { error: null };
        }
        const error: JoinCustomerErrorResponse = await res.json();
        return { error: error.error };
    } catch {
        return { error: ErrorMessages.NETWORK_ISSUE };
    }
};

export const createCustomer = async (
    ...args: Parameters<CreateCustomer>
): Promise<TCreateCustomerUtilReturn> => {
    try {
        const response = await createCustomerMethod(...args);
        if (response.ok) {
            const data: CreateCustomerResponse = await response.json();
            return { error: null, data };
        }
        const error: CreateCustomerErrorResponse = await response.json();
        return {
            error: mapErrorCodeToMessage(error),
            data: null,
        };
    } catch {
        return {
            error: {
                full_name: '',
                birthday: '',
                phone_number: '',
                email: '',
                generic: ErrorMessages.NETWORK_ISSUE,
            },
            data: null,
        };
    }
};

export const getCustomerStatus = async (
    ...args: Parameters<GetCustomerStatus>
): Promise<TGetCustomerStatusUtilReturn> => {
    try {
        const response = await getCustomerStatusMethod(...args);
        if (response.ok) {
            const data: GetCustomerStatusResponse = await response.json();
            return { error: null, data };
        }
        const error: GetCustomerStatusErrorResponse = await response.json();
        return {
            error: mapErrorCodeToMessage(error),
            data: null,
        };
    } catch {
        return {
            error: {
                phone_number: '',
                email: '',
                generic: ErrorMessages.NETWORK_ISSUE,
            },
            data: null,
        };
    }
};
