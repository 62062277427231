import { FC, SyntheticEvent, useRef } from 'react';
import clsx from 'clsx';
import { ClearSolidIcon, MailIcon } from 'shared/icons';
import styles from './Input.module.scss';

type Props = {
    value: string;
    onChange: (value: string) => void;
    type?: string;
    onClear?: () => void;
    hasIcon?: boolean;
    placeholder?: string;
    label?: string;
    className?: string;
    /** error label. If not empty, input will have styles for error state */
    errorMessage?: string;
    /** should have focus */
    hasAutofocus?: boolean;
    /** should be required */
    required?: boolean;
};

const Input: FC<Props> = ({
    value,
    onChange,
    type = 'text',
    onClear,
    placeholder,
    hasIcon = false,
    label,
    className,
    errorMessage,
    hasAutofocus = false,
    required,
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const handleClear = () => {
        onClear?.();
        inputRef.current?.focus();
    };

    const handleChange = (evt: SyntheticEvent<HTMLInputElement>) => {
        const { value } = evt.currentTarget;
        onChange(value);
    };

    return (
        <div
            className={clsx(
                styles.Wrapper,
                { [styles.WithError]: Boolean(errorMessage) },
                className
            )}
        >
            {label && (
                <label className={styles.Label} htmlFor="email-input">
                    {label}
                </label>
            )}
            <div className={styles.InputWrapper}>
                <input
                    required={required}
                    id="email-input"
                    type={type}
                    autoComplete="email"
                    placeholder={placeholder}
                    aria-label="email address"
                    autoCapitalize="none"
                    className={clsx(styles.Input, {
                        [styles.InputWithIcon]: hasIcon,
                        [styles.InputWithError]: Boolean(errorMessage),
                    })}
                    onChange={handleChange}
                    value={value}
                    ref={inputRef}
                    autoFocus={hasAutofocus}
                />
                {hasIcon && (
                    <MailIcon
                        className={styles.Icon}
                        aria-hidden="true"
                        data-testid="mail-icon"
                    />
                )}
                {Boolean(value) && (
                    <button
                        type="button"
                        aria-label="remove entered email"
                        role="button" // it is implicit, but we need it for a unit test
                        className={styles.ClearButton}
                        onClick={handleClear}
                        tabIndex={-1}
                    >
                        <ClearSolidIcon className={styles.ClearIcon} />
                    </button>
                )}
            </div>
            {Boolean(errorMessage) && (
                <span
                    className={styles.ErrorMessage}
                    data-testid="email-error-message"
                >
                    {errorMessage}
                </span>
            )}
        </div>
    );
};

export default Input;
