import { FC } from 'react';
import clsx from 'clsx';
import styles from './PrimaryButton.module.scss';

type Props = {
    onClick?: () => void;
    icon?: JSX.Element;
    type: 'submit' | 'reset' | 'button';
    className?: string;
    disabled?: boolean;
};

const PrimaryButton: FC<Props> = ({
    onClick,
    type = 'button',
    icon,
    children,
    className,
    disabled = false,
}) => {
    return (
        <button
            className={clsx(styles.Button, styles.PrimaryButton, className)}
            onClick={onClick}
            type={type}
            disabled={disabled}
        >
            <span className={styles.Text}>{children}</span>
            {icon}
        </button>
    );
};

export default PrimaryButton;
