import React, { FC } from 'react';

import { Modal } from 'components';
import styles from './ThankYouModal.module.scss';

interface IThankYouModalProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    isExpiredDeal?: boolean;
    isLoyalty?: boolean;
}

const getText = (
    isExpiredDeal: boolean,
    isLoyalty: boolean
): Record<'title' | 'content', string> => {
    if (isExpiredDeal) {
        return {
            title: 'Thanks for signing up!',
            content: 'Keep an eye on your inbox for future offers.',
        };
    }

    if (isLoyalty) {
        return {
            title: 'Thank you!',
            content: 'Thanks for joining our Loyalty program!',
        };
    }

    return {
        title: 'Deal sent!',
        content:
            'Please check your inbox for your deal code and instructions on how to redeem.',
    };
};

const ThankYouModal: FC<IThankYouModalProps> = ({
    isOpen,
    setIsOpen,
    isExpiredDeal,
    isLoyalty,
}) => (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} title="Reward sent">
        <div className={styles.ImageContainer}>
            <img
                src="/images/thankyou.png"
                alt=""
                aria-hidden="true"
                className={styles.Image}
            />
        </div>
        <p className={styles.Title}>
            {getText(!!isExpiredDeal, !!isLoyalty).title}
        </p>
        <p className={styles.Description}>
            {getText(!!isExpiredDeal, !!isLoyalty).content}
        </p>
    </Modal>
);

export default ThankYouModal;
