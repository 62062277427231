import React from 'react';
import Select from 'react-select';
import styles from './SelectInput.module.scss';
import { customStyles } from './SelectInput.constants';
import { Option, SelectInputProps } from './SelectInput.types';

export const SelectInput: React.FC<SelectInputProps> = ({
    options,
    onChange,
    value,
    label,
    name,
    placeholder,
    required,
    errorMessage,
}) => {
    return (
        <div className="select-container">
            {label && (
                <label htmlFor={name} id={name} className={styles.InputLabel}>
                    {label}
                    {required && (
                        <span className={styles.RequiredIndicator}>*</span>
                    )}
                </label>
            )}
            <Select
                options={options}
                onChange={(selectedItem) => onChange(selectedItem as Option)}
                value={value}
                styles={customStyles(!!errorMessage)}
                placeholder={placeholder}
                name={name}
                aria-labelledby={name}
                required={required}
            />
            {errorMessage && (
                <span className={styles.ErrorMessage}>{errorMessage}</span>
            )}
        </div>
    );
};
