import { FC } from 'react';
import { IPlayerProps, Player } from '@lottiefiles/react-lottie-player';
import clsx from 'clsx';

import LoadingIndicatorAnimation from './animation.json';

type Props = Pick<IPlayerProps, 'style'> & {
    className?: string;
};

const LoadingIndicator: FC<Props> = ({ className, style }) => {
    return (
        <div className={clsx(className)}>
            <Player
                autoplay
                loop
                src={LoadingIndicatorAnimation}
                style={style}
            />
        </div>
    );
};

export default LoadingIndicator;
