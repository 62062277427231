import React, { FC, SyntheticEvent, useState } from 'react';
import { Value } from 'react-phone-number-input/input';

import {
    Input,
    LoadingOverlay,
    PhoneInput,
    PrimaryButton,
    SecondaryButton,
} from 'components';
import styles from './CustomerModalForm.module.scss';
import clsx from 'clsx';
import { DateInput } from 'components';
import { ChevronRightIcon } from 'shared/icons';
import {
    updateCustomer,
    joinCustomer,
    createCustomer,
} from 'shared/api/v2/customers/customers.utils';
import { getMaxBirthDate, noop } from 'shared/utils';
import {
    claimUniqueCodeDeal,
    claimCustomerServiceDeal,
} from 'shared/api/v2/deals/deals.data';
import { SelectInput } from 'components/SelectInput/SelectInput';
import {
    CustomerStatus,
    TCustomerData,
    TCustomerStatus,
} from 'shared/api/v2/customers/customers.types';
import { DealKind, TMerchantAddress, TSourceKey } from 'shared/types';

export interface ICustomerModalFormProps {
    className?: string;
    onCancel: () => void;
    customerEmail: string;
    customerId?: string;
    customerStatus?: TCustomerStatus;
    isRootMerchant: boolean;
    dealLocations?: TMerchantAddress[];
    dealId: string;
    source: TSourceKey;
    campaignId?: string;
    dealKind?: DealKind;
    setIsDealSentOpen?: (isOpen: boolean) => void;
    setIsCustomerModalOpen?: (isOpen: boolean) => void;
    setIsThankYouOpen?: (isOpen: boolean) => void;
    isExpiredDeal?: boolean;
}

const CustomerModalForm: FC<ICustomerModalFormProps> = ({
    className,
    onCancel,
    customerEmail,
    customerStatus,
    dealId,
    dealKind,
    isRootMerchant,
    setIsCustomerModalOpen,
    setIsDealSentOpen,
    setIsThankYouOpen,
    source,
    campaignId,
    dealLocations,
    customerId,
    isExpiredDeal,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [favoriteLocation, setFavoriteLocation] = useState<string>();
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState<Value | undefined>();
    const [date, setDate] = useState('');
    const [validationErrors, setValidationErrors] = useState<Partial<
        Record<keyof Omit<TCustomerData, 'customer_id'> | 'generic', string>
    > | null>(null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let claim: (...anyargs: any[]) => void = noop;

    switch (dealKind) {
        case 'ucd':
            claim = claimUniqueCodeDeal;
            break;
        case 'csd':
            claim = claimCustomerServiceDeal;
            break;
        default:
            break;
    }

    const dealLocationOptions = dealLocations?.map((location) => ({
        label: location.street,
        value: location.merchant_id,
    }));

    const dealLocationPreparedValue = dealLocationOptions?.find(
        (option) => option.value === favoriteLocation
    );

    if (!(customerStatus || customerId) && !isRootMerchant) {
        return null;
    }

    const handleSubmit = async (evt: SyntheticEvent<HTMLFormElement>) => {
        evt.preventDefault();
        setIsLoading(true);
        try {
            // For root merchants, we have a different claiming flow
            // See diagram https://app.diagrams.net/#G1AA0z2bacAI2-LkKWrXdOgZppzSXgqCvg
            if (isRootMerchant) {
                if (!favoriteLocation) {
                    return;
                }
                let claimWithcustomerId = customerId;

                // If customer exists, but is not connected to this merchant, then join
                if (
                    customerStatus === CustomerStatus.NOT_CONNECTED &&
                    claimWithcustomerId
                ) {
                    const { error } = await joinCustomer(claimWithcustomerId, {
                        merchant_id: favoriteLocation,
                        source,
                        campaign_id: campaignId,
                    });
                    if (error) {
                        setValidationErrors({ generic: error });
                        return;
                    }
                    // Otherwise, if it's a new customer, create the customer (joined automatically)
                } else if (customerStatus === CustomerStatus.NOT_FOUND) {
                    const { data: newCustomer, error } = await createCustomer({
                        merchant_id: favoriteLocation,
                        email: customerEmail,
                        source,
                        customer_info: {
                            phone_number: phoneNumber?.toString() ?? undefined,
                            birthday: date.length ? date : undefined,
                            full_name: name?.trim().length
                                ? name.trim()
                                : undefined,
                        },
                        campaign_id: campaignId,
                    });
                    if (error || !newCustomer) {
                        setValidationErrors(error);
                        return;
                    }
                    claimWithcustomerId = newCustomer.customer_id;
                }

                // Finally, if this not a deal which expired,
                // then claim the deal, and open/close modals acordingly
                if (!isExpiredDeal) {
                    await claim(dealId as string, {
                        source,
                        customer_id: claimWithcustomerId as string,
                    });
                }
            } else {
                const { error } = await updateCustomer(`${customerId}`, {
                    phone_number: phoneNumber?.toString(),
                    birthday: date,
                    full_name: name,
                });
                if (error) {
                    setValidationErrors(error);
                    return;
                }
            }
            setIsCustomerModalOpen?.(false);
            if (!isExpiredDeal) {
                setIsDealSentOpen?.(true);
            } else {
                setIsThankYouOpen?.(true);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleNoThanksOnExpiredDeal = () => {
        setIsCustomerModalOpen?.(false);
        setIsThankYouOpen?.(true);
    };

    return (
        <>
            {isLoading && <LoadingOverlay />}
            <form
                className={clsx(styles.Form, className)}
                onSubmit={handleSubmit}
            >
                <div>
                    {isRootMerchant && (
                        <SelectInput
                            name="location"
                            label="Select your favorite location"
                            value={dealLocationPreparedValue}
                            onChange={(opt) => {
                                setFavoriteLocation(opt?.value ?? '');
                            }}
                            options={dealLocationOptions ?? []}
                            required
                            errorMessage={validationErrors?.generic}
                        />
                    )}
                    {!(
                        isRootMerchant &&
                        customerStatus === CustomerStatus.NOT_CONNECTED
                    ) && (
                        <>
                            <Input
                                value={name}
                                onChange={setName}
                                onClear={() => setName('')}
                                label="Full name"
                                placeholder="e.g. John Smith"
                                hasAutofocus
                                required={!isRootMerchant}
                                errorMessage={validationErrors?.full_name}
                            />
                            <PhoneInput
                                value={phoneNumber}
                                onChange={setPhoneNumber}
                                label="Phone number"
                                required={!isRootMerchant}
                                errorMessage={validationErrors?.phone_number}
                            />
                            <DateInput
                                value={date}
                                onChange={setDate}
                                label="Birthday"
                                placeholder="MM/DD/YYYY"
                                max={getMaxBirthDate()}
                                required={!isRootMerchant}
                                errorMessage={validationErrors?.birthday}
                            />
                        </>
                    )}
                </div>
                <div className={styles.ButtonWrapper}>
                    <PrimaryButton
                        type="submit"
                        icon={<ChevronRightIcon />}
                        className={styles.PrimaryButton}
                        disabled={
                            isLoading || (isRootMerchant && !favoriteLocation)
                        }
                    >
                        Submit
                    </PrimaryButton>

                    {!isRootMerchant && (
                        <SecondaryButton
                            className={styles.SecondaryButton}
                            onClick={
                                isExpiredDeal
                                    ? handleNoThanksOnExpiredDeal
                                    : onCancel
                            }
                        >
                            No, thanks
                        </SecondaryButton>
                    )}
                </div>
            </form>
        </>
    );
};

export default CustomerModalForm;
