import React, { FC } from 'react';

import { LoyaltyCustomerModalForm, Modal } from 'components';
import styles from './LoyaltyCustomerModal.module.scss';
import { ILoyaltyCustomerModalFormProps } from 'components/LoyaltyCustomerModalForm/LoyaltyCustomerModalForm';

type TModalFormProps = Omit<
    ILoyaltyCustomerModalFormProps,
    'onCancel' | 'setIsCustomerModalOpen'
>;

interface ILoyaltyCustomerModalProps extends TModalFormProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    isRootMerchant: boolean;
}

const LoyaltyCustomerModal: FC<ILoyaltyCustomerModalProps> = ({
    isOpen,
    setIsOpen,
    isRootMerchant,
    ...rest
}) => {
    const description = isRootMerchant
        ? 'Please share a bit more about yourself so that we can personalize your future offers'
        : "If you'd like, let us know a little more about you so that we can personalize your future offers.";

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            title={
                isRootMerchant ? 'Just one more step!' : 'Thanks for Signing Up'
            }
            className={styles.Modal}
        >
            <p className={styles.Title}>
                {isRootMerchant
                    ? 'Just one more step!'
                    : 'Thanks for Signing   Up'}
            </p>
            <p className={styles.Description}>{description}</p>
            <LoyaltyCustomerModalForm
                className={styles.Form}
                onCancel={() => {
                    rest.setIsThankYouOpen?.(true);
                    setIsOpen(false);
                }}
                isRootMerchant={isRootMerchant}
                setIsCustomerModalOpen={setIsOpen}
                {...rest}
            />
        </Modal>
    );
};

export default LoyaltyCustomerModal;
