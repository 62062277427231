import clsx from 'clsx';
import { E164Number } from 'libphonenumber-js/types';
import { FC } from 'react';
import { default as ReactPhoneNumberInput } from 'react-phone-number-input/input';

import styles from './PhoneInput.module.scss';

type Props = {
    value: E164Number | undefined;
    /** function called when value is updated from user input*/
    onChange: (number: string) => void;
    /** label placed on top of input */
    label?: string;
    /** error label. If not empty, input will have styles for error state */
    errorMessage?: string;
    /** should have focus */
    hasAutofocus?: boolean;
    /** should be required */
    required?: boolean;
};

const PhoneInput: FC<Props> = ({
    value,
    onChange,
    label,
    errorMessage,
    hasAutofocus = false,
    required,
}) => {
    return (
        <div className={styles.Wrapper}>
            {label && (
                <label
                    htmlFor="phone-input"
                    className={styles.Label}
                    id="phone-desc"
                >
                    {label}
                </label>
            )}
            <div className={styles.InputWrapper}>
                <ReactPhoneNumberInput
                    id="phone-input"
                    country="US"
                    international={false}
                    value={value}
                    onChange={onChange}
                    autoFocus={hasAutofocus}
                    autoComplete="tel"
                    required={required}
                    placeholder="e.g. (555) 555 555"
                    className={clsx(styles.PhoneInput, {
                        [styles.InputWithError]: Boolean(errorMessage),
                    })}
                />
            </div>
            {Boolean(errorMessage) && (
                <span
                    className={styles.ErrorMessage}
                    data-testid="email-error-message"
                >
                    {errorMessage}
                </span>
            )}
        </div>
    );
};

export default PhoneInput;
