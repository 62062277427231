import {
    GetCustomerStatusResponse,
    CustomerStatus,
} from 'shared/api/v2/customers/customers.types';

export const resolveCustomerStatus = (
    customerByPhone: GetCustomerStatusResponse | null,
    customerByEmail: GetCustomerStatusResponse | null
): {
    customerStatus: CustomerStatus;
    customerId: string | null;
    shouldUpdate: 'phone' | 'email' | null;
} => {
    if (!customerByPhone && !customerByEmail) {
        return {
            customerStatus: CustomerStatus.NOT_FOUND,
            customerId: null,
            shouldUpdate: null,
        };
    }

    const phoneStatus = customerByPhone
        ? customerByPhone.status
        : CustomerStatus.NOT_FOUND;
    const emailStatus = customerByEmail
        ? customerByEmail.status
        : CustomerStatus.NOT_FOUND;
    const phoneCustomerId = customerByPhone
        ? customerByPhone.customer_id
        : null;
    const emailCustomerId = customerByEmail
        ? customerByEmail.customer_id
        : null;

    // If both are NOT_CONNECTED, continue with phone
    if (
        phoneStatus === CustomerStatus.NOT_CONNECTED &&
        (emailStatus === CustomerStatus.NOT_CONNECTED ||
            emailStatus === CustomerStatus.NOT_FOUND)
    ) {
        return {
            customerStatus: CustomerStatus.NOT_CONNECTED,
            customerId: phoneCustomerId,
            shouldUpdate:
                emailStatus === CustomerStatus.NOT_FOUND ? 'email' : null,
        };
    }

    // No phone found, continue with email
    if (
        phoneStatus === CustomerStatus.NOT_FOUND &&
        emailStatus === CustomerStatus.NOT_CONNECTED
    ) {
        return {
            customerStatus: CustomerStatus.NOT_CONNECTED,
            customerId: emailCustomerId,
            shouldUpdate: 'phone',
        };
    }

    // customer does not exist
    if (
        phoneStatus === CustomerStatus.NOT_FOUND &&
        emailStatus === CustomerStatus.NOT_FOUND
    ) {
        return {
            customerStatus: CustomerStatus.NOT_FOUND,
            customerId: null,
            shouldUpdate: null,
        };
    }

    return {
        customerStatus: CustomerStatus.CONNECTED,
        customerId: phoneCustomerId || emailCustomerId,
        shouldUpdate: null,
    };
};
