import { getTextColorBasedOnBgColor } from '../../shared/utils/getTextColorBasedOnBgColor/getTextColorBasedOnBgColor';
import { EmailIcon, PhoneIcon, PinIcon } from '../../shared/icons';
import clsx from 'clsx';
import React from 'react';
import { GetMerchantInfoResponse } from 'shared/api/v2/merchants/merchants.types';
import { COLOR_WHITE, DEFAULT_BG_COLOR } from '../../shared/constants/colors';
import styles from './Footer.module.scss';
import SpotonSignature from '../../public/images/spotonsignature.svg';
import parsePhoneNumber from 'libphonenumber-js';
import { parseAddress } from 'utils/parseAddress';

type FooterProps = {
    merchantInfo: GetMerchantInfoResponse;
};
export const Footer: React.FC<FooterProps> = ({ merchantInfo }) => {
    const isDarkBg =
        getTextColorBasedOnBgColor(
            merchantInfo.primary_color || DEFAULT_BG_COLOR
        ) === COLOR_WHITE;

    const parsedPhoneNumber = parsePhoneNumber(merchantInfo.phone_number, 'US');

    const merchantDataConfig = [
        {
            Icon: PinIcon,
            element: parseAddress(merchantInfo.address),
            type: 'address',
            value: parseAddress(merchantInfo.address),
        },
        {
            Icon: PhoneIcon,
            type: 'phone',
            value: merchantInfo.phone_number,
            element: (
                <a href={`tel:${merchantInfo.phone_number}`}>
                    {parsedPhoneNumber
                        ? parsedPhoneNumber.formatNational()
                        : merchantInfo.phone_number}
                </a>
            ),
        },
        {
            Icon: EmailIcon,
            type: 'email',
            value: merchantInfo.email,
            element: (
                <a href={`mailto:${merchantInfo.email}`}>
                    {merchantInfo.email}
                </a>
            ),
        },
    ];
    return (
        <footer
            className={styles.Footer}
            style={{
                backgroundColor: merchantInfo.primary_color || DEFAULT_BG_COLOR,
                color: getTextColorBasedOnBgColor(
                    merchantInfo.primary_color || DEFAULT_BG_COLOR
                ),
            }}
        >
            <section>
                <h4
                    className={styles.FooterTitle}
                    style={{ color: isDarkBg ? COLOR_WHITE : '' }}
                >
                    {merchantInfo.business_name}
                </h4>
                <div className={styles.FooterContent}>
                    {merchantDataConfig.map((config) => {
                        if (!config.value) {
                            return null;
                        }
                        return (
                            <p key={config.type}>
                                <config.Icon
                                    className={
                                        isDarkBg
                                            ? styles.LightIcon
                                            : styles.DarkIcon
                                    }
                                />{' '}
                                {config.element}
                            </p>
                        );
                    })}
                </div>
            </section>

            <div
                className={clsx(styles.FooterSpotonSignature, {
                    [styles.SpotonSignatureLight]: isDarkBg,
                    [styles.SpotonSignatureDark]: !isDarkBg,
                })}
            >
                <SpotonSignature />
            </div>
        </footer>
    );
};
