import { FC } from 'react';
import clsx from 'clsx';
import styles from './SecondaryButton.module.scss';

type Props = {
    onClick?: () => void;
    icon?: JSX.Element;
    className?: string;
    disabled?: boolean;
};

const SecondaryButton: FC<Props> = ({
    onClick,
    icon,
    children,
    className,
    disabled = false,
}) => {
    return (
        <button
            className={clsx(styles.Button, styles.SecondaryButton, className)}
            onClick={onClick}
            type="button"
            disabled={disabled}
        >
            <span className={styles.Text}>{children}</span>
            {icon}
        </button>
    );
};

export default SecondaryButton;
