import clsx from 'clsx';
import { FC } from 'react';

import { LoadingIndicator } from 'components';
import styles from './LoadingOverlay.module.scss';

type Props = {
    className?: string;
    hasBackgroundBlur?: boolean;
};
const LoadingOverlay: FC<Props> = ({
    className,
    hasBackgroundBlur = false,
}) => {
    return (
        <div
            className={clsx(styles.Overlay, className, {
                [styles.Overlay___blurred]: hasBackgroundBlur,
            })}
            role="status"
            aria-label="Do not refresh the page"
        >
            <LoadingIndicator style={{ height: 200 }} />
        </div>
    );
};

export default LoadingOverlay;
