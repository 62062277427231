import clsx from 'clsx';
import { FC } from 'react';
import { default as ReactModal } from 'react-modal';

import { CloseIcon } from 'shared/icons';
import styles from './Modal.module.scss';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    className?: string;
};

const Modal: FC<Props> = ({ children, isOpen, onClose, className }) => {
    return (
        <ReactModal
            isOpen={isOpen}
            shouldCloseOnOverlayClick={false}
            onRequestClose={onClose}
            className={clsx(styles.Modal, className)}
            overlayClassName={styles.Overlay}
        >
            <div className={styles.ModalHeader}>
                {onClose && (
                    <CloseIcon onClick={onClose} className={styles.CloseIcon} />
                )}
            </div>
            {children}
        </ReactModal>
    );
};

export default Modal;
