import type { FC } from 'react';
import clsx from 'clsx';

import LogoEmblem from 'public/images/emblem.svg';
import styles from './LegacyFlowScreen.module.scss';
import type { LegacyClaimStatus, LegacyFlowProps } from 'pages';
import { formatDateWithTimezone } from 'shared/utils';

const titleByStatus: Record<LegacyClaimStatus, string> = {
    success: 'Go ahead and redeem your deal!',
    deal_already_claimed:
        'Your deal is reserved. Visit the store to redeem it.',
    deal_already_redeemed: 'This deal has been redeemed.',
};

const LegacyFlowScreen: FC<LegacyFlowProps> = ({
    dealData,
    status = 'success',
}) => {
    return (
        <div className={styles.Wrapper}>
            <LogoEmblem className={styles.LogoEmblem} aria-hidden="true" />
            <h1 className={styles.Title}>
                <div className={styles.TitleContent}>
                    {titleByStatus[status]}
                </div>
            </h1>
            <p className={styles.Description}>
                Simply provide your email address, scan the SpotOn mobile app or
                scan your loyalty card at the counter.
            </p>
            {dealData && (
                <>
                    <p className={styles.Description}>
                        Your deal at {dealData.merchant_name}:
                    </p>
                    <p className={clsx(styles.TitleContent, styles.PromoName)}>
                        {dealData.deal_name}
                    </p>
                    <p className={styles.Description}>
                        Expires:{' '}
                        {formatDateWithTimezone(
                            dealData.end_datetime,
                            dealData.timezone
                        )}{' '}
                    </p>
                </>
            )}
        </div>
    );
};

export default LegacyFlowScreen;
