import styles from './LocationsDropdown.module.scss';
import clsx from 'clsx';
import { CollapseIcon, ExpandIcon } from '../../shared/icons';
import React, { useState } from 'react';
import { GetUniqueCodeDealInfoResponse } from 'shared/api/v2/deals/deals.types';

export const LocationsDropdown = ({
    locations,
}: {
    locations: GetUniqueCodeDealInfoResponse['locations'];
}) => {
    const [areLocationsVisible, setAreLocationsVisible] =
        useState<boolean>(true);
    return (
        <div className={styles.Locations}>
            <div
                className={styles.Title}
                onClick={() => setAreLocationsVisible((prev) => !prev)}
            >
                This deal is redeemable at the following locations{' '}
                {areLocationsVisible ? (
                    <CollapseIcon className={styles.Icon} />
                ) : (
                    <ExpandIcon className={styles.Icon} />
                )}
            </div>
            <ul
                className={clsx(styles.List, {
                    [styles.LocationVisible]: areLocationsVisible,
                })}
            >
                {locations.map(({ street }, index) => (
                    <li key={`${street}-${index}`} className={styles.Name}>
                        {street}
                    </li>
                ))}
            </ul>
        </div>
    );
};
