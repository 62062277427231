import React, { FC } from 'react';
import { GetMerchantInfoResponse } from 'shared/api/v2/merchants/merchants.types';

import Logo from '../../public/images/merchantlogoplaceholder.svg';
import styles from './PageLayout.module.scss';
import clsx from 'clsx';
import { Footer } from '../Footer/Footer';

type Props = {
    isFooterVisible?: boolean;
    isLogoVisible?: boolean;
    logoClassName?: string;
    merchantInfo?: GetMerchantInfoResponse;
};

const PageLayout: FC<Props> = ({
    children,
    isFooterVisible = true,
    isLogoVisible = true,
    logoClassName,
    merchantInfo,
}) => {
    const renderLogo = merchantInfo?.logo_url ? (
        <img src={merchantInfo.logo_url} className={styles.MerchantLogo} />
    ) : (
        <Logo />
    );
    return (
        <div className={styles.Layout}>
            <main className={styles.Wrapper}>
                <section
                    className={clsx(
                        styles.Logo,
                        logoClassName && logoClassName
                    )}
                >
                    {isLogoVisible && renderLogo}
                </section>
                {children}
            </main>
            {isFooterVisible && !!merchantInfo && (
                <Footer merchantInfo={merchantInfo} />
            )}
        </div>
    );
};

export default PageLayout;
