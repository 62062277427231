export const DEFAULT_BG_COLOR = '#e8f0ff';
export const COLOR_WHITE = '#ffffff';
export const COLOR_BLACK = '#08031d';

export const COLOR_BLUE = {
    10: "#e8f0ff",
    20: "#b5cffe",
    30: "#7dabff",
    50: "#1769ff",
    70: "#1254cc",
    80: "#0e3f99",
    90: "#092a66",
};

export const COLOR_RED = {
    10: "#f8dddd",
    20: "#fcbcbb",
    30: "#f97877",
    50: "#f73e3c",
    70: "#b92624",
    80: "#a41313",
    90: "#8c0000"
};