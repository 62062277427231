import React, { FC } from 'react';
import clsx from 'clsx';

import { ClaimForm } from 'components';
import { IClaimFormProps } from 'components/ClaimForm/ClaimForm';

import PresentBox from '../../public/images/presentbox.svg';
import styles from './ClaimScreenLegacy.module.scss';

interface IClaimScreenLegacyProps extends IClaimFormProps {
    merchantName?: string;
    thumbnail?: string;
}

const ClaimScreenLegacy: FC<IClaimScreenLegacyProps> = ({
    merchantName,
    thumbnail,
    ...rest
}) => {
    return (
        <div className={styles.Wrapper}>
            <section className={styles.FormSection}>
                {merchantName && (
                    <h1 className={styles.Title}>{merchantName}</h1>
                )}
                <p className={styles.Description}>
                    Reserve this deal and access more offers
                </p>
                <ClaimForm {...rest} />
            </section>
            <div
                className={clsx(styles.ThumbnailContainer, {
                    [styles.ThumbnailScale]: !thumbnail,
                })}
            >
                {thumbnail ? (
                    <img
                        src={thumbnail}
                        className={styles.ThumbnailShadow}
                        alt="thumnbail"
                    />
                ) : (
                    <PresentBox />
                )}
            </div>
        </div>
    );
};

export default ClaimScreenLegacy;
