export const getTextColorBasedOnBgColor = (
    color: string
): '#000000' | '#ffffff' => {
    // Remove leading '#' if present
    const cleanedColor = color.startsWith('#') ? color.substring(1) : color;

    // Convert the color to RGB values
    const r = parseInt(cleanedColor.substring(0, 2), 16);
    const g = parseInt(cleanedColor.substring(2, 4), 16);
    const b = parseInt(cleanedColor.substring(4, 6), 16);

    // Calculate the luminance using the relative luminance formula
    const luminance = (r * 0.299 + g * 0.587 + b * 0.114) / 255;

    // Determine the appropriate color based on the luminance
    return luminance > 0.5 ? '#000000' : '#ffffff';
};
