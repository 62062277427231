import React, { FC, SyntheticEvent, useState } from 'react';

import {
    Input,
    LoadingOverlay,
    PrimaryButton,
    SecondaryButton,
} from 'components';
import styles from './LoyaltyCustomerModalForm.module.scss';
import clsx from 'clsx';
import { DateInput } from 'components';
import { ChevronRightIcon } from 'shared/icons';
import {
    updateCustomer,
    joinCustomer,
    createCustomer,
} from 'shared/api/v2/customers/customers.utils';
import { getMaxBirthDate } from 'shared/utils';

import { SelectInput } from 'components/SelectInput/SelectInput';
import {
    CustomerStatus,
    TCustomerData,
    TCustomerStatus,
} from 'shared/api/v2/customers/customers.types';
import { TSourceKey, TUTMMediumKey } from 'shared/types';
import { TBasicMerchantLocation } from 'shared/api/v2/merchants/merchants.types';
import { ErrorMessages } from 'shared/constants/errorMessages';

export interface ILoyaltyCustomerModalFormProps {
    className?: string;
    onCancel: () => void;
    customerEmail: string;
    customerPhoneNumber?: string;
    customerId: string | null;
    customerStatus?: TCustomerStatus;
    isRootMerchant: boolean;
    merchantLocations?: TBasicMerchantLocation[];
    source: TSourceKey;
    medium?: TUTMMediumKey;
    setIsCustomerModalOpen?: (isOpen: boolean) => void;
    setIsThankYouOpen?: (isOpen: boolean) => void;
}

const LoyaltyCustomerModalForm: FC<ILoyaltyCustomerModalFormProps> = ({
    className,
    onCancel,
    customerEmail,
    customerStatus,
    customerPhoneNumber,
    isRootMerchant,
    setIsCustomerModalOpen,
    setIsThankYouOpen,
    source,
    merchantLocations,
    customerId,
    medium,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [favoriteLocation, setFavoriteLocation] = useState<string>();
    const [name, setName] = useState('');
    const [date, setDate] = useState('');
    const [validationErrors, setValidationErrors] = useState<Partial<
        Record<keyof Omit<TCustomerData, 'customer_id'> | 'generic', string>
    > | null>(null);

    const dealLocationOptions = merchantLocations?.map((location) => ({
        label: location.address,
        value: location.merchant_id,
    }));

    const dealLocationPreparedValue = dealLocationOptions?.find(
        (option) => option.value === favoriteLocation
    );

    if (!(customerStatus || customerId) && !isRootMerchant) {
        return null;
    }

    const handleSubmit = async (evt: SyntheticEvent<HTMLFormElement>) => {
        evt.preventDefault();
        setIsLoading(true);
        try {
            // For root merchants, we have a different claiming flow
            // See diagram https://app.diagrams.net/#G1AA0z2bacAI2-LkKWrXdOgZppzSXgqCvg
            if (isRootMerchant) {
                if (!favoriteLocation) {
                    return;
                }
                const claimWithcustomerId = customerId;

                // If customer exists, but is not connected to this merchant, then join
                if (
                    customerStatus === CustomerStatus.NOT_CONNECTED &&
                    claimWithcustomerId
                ) {
                    const { error } = await joinCustomer(claimWithcustomerId, {
                        merchant_id: favoriteLocation,
                        source,
                        medium,
                    });
                    if (error) {
                        setValidationErrors({ generic: error });
                        return;
                    }
                    // Otherwise, if it's a new customer, create the customer (joined automatically)
                } else if (customerStatus === CustomerStatus.NOT_FOUND) {
                    const { data: newCustomer, error } = await createCustomer({
                        merchant_id: favoriteLocation,
                        email: customerEmail,
                        source,
                        medium,
                        customer_info: {
                            phone_number: customerPhoneNumber,
                            birthday: date.length ? date : undefined,
                            full_name: name?.trim().length
                                ? name.trim()
                                : undefined,
                        },
                    });
                    if (error || !newCustomer) {
                        setValidationErrors(error);
                        return;
                    }
                }
            } else {
                const { error } = await updateCustomer(`${customerId}`, {
                    phone_number: customerPhoneNumber,
                    birthday: date,
                    full_name: name,
                });
                if (error) {
                    setValidationErrors(error);
                    return;
                }
            }
            setIsCustomerModalOpen?.(false);

            setIsThankYouOpen?.(true);
        } catch (error) {
            setValidationErrors({
                generic: ErrorMessages.DEFAULT,
            });
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading && <LoadingOverlay />}
            <form
                className={clsx(styles.Form, className)}
                onSubmit={handleSubmit}
            >
                <div>
                    {isRootMerchant && (
                        <SelectInput
                            name="location"
                            label="Select your favorite location"
                            value={dealLocationPreparedValue}
                            onChange={(opt) => {
                                setFavoriteLocation(opt?.value ?? '');
                            }}
                            options={dealLocationOptions ?? []}
                            required
                            errorMessage={validationErrors?.generic}
                        />
                    )}
                    {!(
                        isRootMerchant &&
                        customerStatus === CustomerStatus.NOT_CONNECTED
                    ) && (
                        <>
                            <Input
                                value={name}
                                onChange={setName}
                                onClear={() => setName('')}
                                label="Full name"
                                placeholder="e.g. John Smith"
                                hasAutofocus
                                required={!isRootMerchant}
                                errorMessage={validationErrors?.full_name}
                            />
                            <DateInput
                                value={date}
                                onChange={setDate}
                                label="Birthday"
                                placeholder="MM/DD/YYYY"
                                max={getMaxBirthDate()}
                                required={!isRootMerchant}
                                errorMessage={validationErrors?.birthday}
                            />
                        </>
                    )}
                </div>
                <div className={styles.ButtonWrapper}>
                    <PrimaryButton
                        type="submit"
                        icon={<ChevronRightIcon />}
                        className={styles.PrimaryButton}
                        disabled={
                            isLoading || (isRootMerchant && !favoriteLocation)
                        }
                    >
                        Submit
                    </PrimaryButton>

                    {!isRootMerchant && (
                        <SecondaryButton
                            className={styles.SecondaryButton}
                            onClick={onCancel}
                        >
                            No, thanks
                        </SecondaryButton>
                    )}
                </div>
            </form>
        </>
    );
};

export default LoyaltyCustomerModalForm;
