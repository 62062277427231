import React, { FC } from 'react';

import { ClaimForm } from 'components';
import { IClaimFormProps } from 'components/ClaimForm/ClaimForm';
import styles from './ClaimScreen.module.scss';
import {
    GetAdhocDealInfoResponse,
    GetUniqueCodeDealInfoResponse,
    isUniqueCodeDealInfoResponse,
} from 'shared/api/v2/deals/deals.types';
import PresentBox from '../../public/images/presentbox.svg';
import { DealDateCounter } from '../DealDateCounter/DealDateCounter';
import { LocationsDropdown } from '../LocationsDropdown/LocationsDropdown';
import clsx from 'clsx';
import { utcToZonedTime } from 'date-fns-tz';
import { formatDiscountText } from '../../shared/utils/formatDiscountText/formatDiscountText';

interface IClaimScreenProps extends IClaimFormProps {
    businessName: string;
    dealData: GetUniqueCodeDealInfoResponse | GetAdhocDealInfoResponse;
}

const ClaimScreen: FC<IClaimScreenProps> = ({
    businessName,
    dealData,
    ...rest
}) => {
    const isUniqueCodeDeal = isUniqueCodeDealInfoResponse(dealData);
    const { discount_type, discount_config, timezone, thumbnail } = dealData;

    const { discountText, conditionText } = formatDiscountText(
        discount_type,
        discount_config
    );

    return (
        <div className={styles.Wrapper}>
            <section className={styles.FormSection}>
                {'start_datetime' in dealData && (
                    <div className={styles.DealTimeContainer}>
                        <DealDateCounter
                            dealStartDate={utcToZonedTime(
                                new Date(dealData.start_datetime),
                                timezone
                            )}
                            dealEndDate={utcToZonedTime(
                                new Date(dealData.end_datetime),
                                timezone
                            )}
                        />
                    </div>
                )}
                <h1 className={styles.Title}>{businessName}</h1>
                <div className={styles.DiscountDescriptionWrapper}>
                    <h2>Claim {discountText}</h2>
                    <p className={styles.DiscountCondition}>{conditionText}</p>
                </div>
                <p className={styles.Description}>
                    {rest.dealKind === 'ahd'
                        ? 'Sign up for this deal to save on your next order. You will also receive future deals/promotions from us!'
                        : 'Reserve this deal and access more offers'}
                </p>
                <ClaimForm {...rest} />
                <p className={styles.TermsAndPolicy}>
                    By proceeding, you agree with{' '}
                    <a href="https://www.spoton.com/legal/">
                        Terms &amp; Conditions
                    </a>{' '}
                    and{' '}
                    <a href="https://www.spoton.com/legal/consumer-privacy/">
                        Privacy Policy
                    </a>{' '}
                </p>
                <p className={styles.CouponInfo}>
                    All coupons are one time use only and only valid for the
                    time frame listed on the coupon.
                </p>
                {isUniqueCodeDeal && (
                    <LocationsDropdown locations={dealData.locations} />
                )}
            </section>

            <div
                className={clsx(styles.ThumbnailContainer, {
                    [styles.ThumbnailScale]: !thumbnail,
                })}
            >
                {thumbnail ? (
                    <img
                        src={thumbnail}
                        className={styles.ThumbnailShadow}
                        alt="thumnbail"
                    />
                ) : (
                    <PresentBox />
                )}
            </div>
        </div>
    );
};

export default ClaimScreen;
