import { GetFetchConfig } from './getFetchConfig.types';

/**
 * returns fetch() API shared config to be used in shared/api folder
 *
 * usage:
 * type PayloadType = {foo: 'bar'};
 * const config = getFetchConfig<PayloadType>({ method: 'POST', payload });
 * await fetch(apiUrl, config);
 *
 * providing payload type (PayloadType in the example above) is optional, but might prevent errors
 *
 */
export const getFetchConfig: GetFetchConfig = ({
    method = 'POST',
    payload,
}) => {
    return {
        method,
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
        },
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(payload || {}),
    };
};
