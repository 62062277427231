import {
    DiscountType,
    TBogoDiscountConfig,
    TCashDiscountConfig,
    TDiscountConfig,
    TFreeItemDiscountConfig,
    TPercentDiscountConfig,
} from '../../api/v2/deals/deals.types';

type DiscountDescription = {
    discountText: string;
    conditionText: string;
};
export const formatDiscountText = (
    discountType: DiscountType,
    discountConfig: TDiscountConfig
): DiscountDescription => {
    const discountDescription = {
        discountText: '',
        conditionText: '',
    };

    if (discountType === 'percent') {
        const percentDiscountConfig = discountConfig as TPercentDiscountConfig;
        discountDescription.discountText = `${Math.round(
            (percentDiscountConfig?.percent || 0) * 100
        )}% off`;
        const conditions = [];
        if (percentDiscountConfig?.min_order_value) {
            conditions.push(
                `on orders over $${percentDiscountConfig.min_order_value}`
            );
        }
        if (percentDiscountConfig?.max_discount_value) {
            conditions.push(
                `up to $${percentDiscountConfig.max_discount_value} off`
            );
        }
        discountDescription.conditionText = conditions.join(', ');
    }
    if (discountType === 'free_item') {
        const freeItemConfig = discountConfig as TFreeItemDiscountConfig;
        discountDescription.discountText = `Free ${freeItemConfig?.item?.name}`;
        discountDescription.conditionText = freeItemConfig?.min_order_value
            ? `on orders over $${freeItemConfig?.min_order_value}`
            : '';
    }
    if (discountType === 'fixed') {
        const fixedDiscountConfig = discountConfig as TCashDiscountConfig;
        discountDescription.discountText = `$${fixedDiscountConfig?.value} off`;
        discountDescription.conditionText = fixedDiscountConfig?.min_order_value
            ? `on orders over $${fixedDiscountConfig?.min_order_value}`
            : '';
    }
    if (discountType === 'buy_one_get_one') {
        discountDescription.discountText = `Buy one ${
            (discountConfig as TBogoDiscountConfig)?.item?.name
        }, get one Free`;
    }
    return discountDescription;
};
